export const techItemDataFE = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "Bootstrap",
  "Tailwind",
  "React",
];

export const techItemDataBE = [
  "Node",
  "Express",
  "MongoDB",
  "PostgresSQL",
  "MVC",
];
